.header-wrapper {
  display: flex;
  /*flex-wrap: wrap;*/
  justify-content: space-between;
  /* height: 150px; */
}

.content {
  margin: 12px auto;
  text-align: left;
}

.title {
  text-align: center;
}

p {
  margin: 0;
  padding: 0;
}

.header__left-content,
.header__right-content {
  position: relative;
  font-size: 21px;
}

.header__left-content > div {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.header__right-content > div {
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.header__left-content__title,
.header__right-content__title {
  font-size: 30px;
  font-weight: bold;
}

.position-absolute {
  position: absolute;
}
